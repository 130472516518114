// import { Chart } from 'chart.js';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

var plugin = {
  id: 'savetopng',

  afterRender: function(chart, args, options) {
    // console.log('PLUGINS SAVETOPNG !!!')
    // console.log(options)
    let filename = options.filename;
    let url = options.url;
    // console.log('URL====>');
    // console.log(url);
    // console.log('FILENAMEL====>');
    // console.log(filename);
    // console.log('CHART')
    // console.log(chart)
    // console.log('ARGS')
    // console.log(args)

    if (filename && url) {
      console.log('IN');
      // save chart canvas to PNG
      let image_datas = chart.canvas.toDataURL('image/png');

      let datas_img = {};
      datas_img = {'image': image_datas, 'filename': filename}

      console.log(datas_img);
      $.ajax({
        url: url,
        type: 'POST',
        data: datas_img,
        // dataType: 'json',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
        success: function(data) {
          console.log('render is done!');
        }
      });
    }
  }
};

// Chart.plugins.register(plugin);
Chart.register(plugin);

var bgcol = [ 'rgba(255, 159, 64, 0.2)', // orange
              'rgba(255, 99, 132, 0.2)', // rose
              'rgba(54, 162, 235, 0.2)', // bleu
              'rgba(255, 206, 86, 0.2)', // jaune
              'rgba(153, 102, 255, 0.2)', // violet
              'rgba(75, 192, 192, 0.2)', // vert]
              'rgba(230, 50, 220, 0.2)', // rose 2
              'rgba(0, 128, 255, 0.2)', // bleu 2
              'rgba(255, 30, 20, 0.2)', // rouge
              'rgba(0, 180, 80, 0.2)', // vert 2
              'rgba(128, 0, 255, 0.2)' // violet 2
            ]
var bdcol = [ 'rgba(255, 159, 64, 1)', // orange
              'rgba(255, 99, 132, 1)', // rose
              'rgba(54, 162, 235, 1)', // bleu
              'rgba(255, 206, 86, 1)', // jaune
              'rgba(153, 102, 255, 1)', // violet
              'rgba(75, 192, 192, 1)', // vert
              'rgba(230, 50, 220, 1)', //
              'rgba(0, 128, 255, 1)', //
              'rgba(255, 30, 20, 1)', // rouge
              'rgba(0, 180, 80, 1)', //
              'rgba(128, 0, 255, 1)', //
            ]

/*
*
* Indicator Charts
*
*/
function draw_indicator_chart(ctx) {
  console.log("FIUNCTION JS PACK DRAW FOR "+ctx.getAttribute('attr-chart-id'));
  let type_chart = ctx.getAttribute('attr-chart-type');

  let datas = JSON.parse(ctx.getAttribute('attr-chart-values'));

  let bg_colors = [],
      bd_colors = [],
      bg_target = [],
      bd_target = [];
  for (var i = 0; i < datas.length; i++) {
    bg_colors.push(bgcol[ctx.getAttribute('attr-chart-color') || 0]); // 'rgba(255, 159, 64, 0.2)');
    bd_colors.push(bdcol[ctx.getAttribute('attr-chart-color') || 0]); // 'rgba(255, 159, 64, 1)');

    bg_target.push('rgba(0, 180, 80, 0.2)');
    bd_target.push('rgba(0, 180, 80, 1)');
  }

  let chart = new Chart(ctx, {
    type: type_chart,
    data: {
      labels: JSON.parse(ctx.getAttribute('attr-chart-labels')),
      datasets: [{
        label: 'Measure',
        data: datas,
        backgroundColor: bg_colors,
        borderColor: bd_colors,
        borderWidth: 1
      },
      {
        label: 'Target',
        data: JSON.parse(ctx.getAttribute('attr-chart-targets')),
        backgroundColor: bg_target,
        borderColor: bd_target,
        type: (type_chart == 'line' || type_chart == 'bar') ? 'line' : type_chart,
        borderWidth: 1,
        fill: false
      }]
    },
    options: {
      responsive: true,
      // maintainAspectRatio: true,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
              beginAtZero: true,
              // max: 100,
              // min: 0
          }
        }]
      },
      plugins: {
          savetopng: {
            filename: 'indicator_'+ctx.getAttribute('attr-chart-id'),
            url: ctx.getAttribute('attr-save-fct')
          }
      }
    }
  });
}

function draw_indicator_charts() {
  let elements = document.getElementsByClassName('indicator-charts');

  for (let ctx of elements) {
    console.log("Draw chart for "+ctx.getAttribute('attr-chart-id'));
    draw_indicator_chart(ctx);
  }
}

/*
*
* Home Page Charts
*
*/
function draw_home_page_charts() {
    let elements = document.getElementsByClassName('home-page-charts');

    // let ctx = document.getElementById('home-page-rca-chart').getContext('2d');
    // console.log(elements);
    for (let ctx of elements) {
      // console.log(ctx.getAttribute('attr-chart-labels'));
      // console.log(ctx.getAttribute('attr-chart-values'));
      let chart_home_page = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: JSON.parse(ctx.getAttribute('attr-chart-labels')), // ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'],
          datasets: [{
            label: '# of Rcas',
            data: JSON.parse(ctx.getAttribute('attr-chart-values')), // [12, 19, 3, 5, 2, 3],
            backgroundColor: bgcol,
            borderColor: bdcol,
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            savetopng: false
          }
        }
      });
    }
  }

document.addEventListener("turbolinks:load", () => {
  draw_indicator_charts();
  draw_home_page_charts();
})


export { draw_indicator_chart }
export { draw_home_page_charts }
